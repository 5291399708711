import { H3, Subhead } from '@xo-union/tk-ui-typography';
import React, { PureComponent } from 'react';

import Styles from './EmptyState.scss';

export default class EmptyState extends PureComponent {
	render() {
		return (
			<div className={Styles.noReviewsContainer}>
				<div className={Styles.bodyWrapper}>
					<img
						src="//media-api.xogrp.com/images/99a4de9d-3f68-4728-b003-2d48b2e10c41"
						alt="gift box with stars"
						className={Styles.bodyImage}
					/>
					<H3 className={Styles.noReviewsHeader}>No reviews yet!</H3>
					<Subhead className={Styles.noReviewsSubHeader}>
						Help out other couples by sharing your thoughts.
					</Subhead>
				</div>
			</div>
		);
	}
}
