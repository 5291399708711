import ReviewsModalVerTwo from '@components/reviews/components/reviews_modal_ver_two';
import isEmpty from 'lodash/isEmpty';
import React, { type FC } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { canonicalUrl } from '../../../helpers/seo';
import MetaContent from '../../components/shared/HelmetHeader/HelmetHeader';
import { useTrackReviewPage } from '../../hooks/useTrackReviewPage';
import Styles from './ReviewWeddingVendors.scss';
import ReviewsWrapper from './containers/ReviewsWrapper/ReviewsWrapper';
import SearchBanner from './containers/SearchBanner';

const REVIEWS_HUB_TITLE = 'Review Your Wedding Vendors';
const REVIEWS_HUB_DESCRIPTION =
	'Write a review of your wedding venue, photographer, florist and more to help other brides plan their weddings.';

type StateProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
	match: {
		params: {
			displayId: string;
			reviewId: string;
		};
	};
}

type ReviewWeddingVendorsProps = StateProps & OwnProps;

const ReviewWeddingVendors: FC<ReviewWeddingVendorsProps> = (props) => {
	const { match, member, pageType } = props;
	const { displayId } = match.params;
	useTrackReviewPage();

	return (
		<>
			<MetaContent
				canonical={canonicalUrl('/review-wedding-vendors')}
				description={REVIEWS_HUB_DESCRIPTION}
				landingPage="reviews"
				title={REVIEWS_HUB_TITLE}
			/>
			<div className={Styles.reviewWeddingVendorsContainer}>
				<SearchBanner />
				{!isEmpty(member) && ( // wait for member to be populated, then fetch reviews
					<ReviewsWrapper member={member} />
				)}
				{displayId && (
					<ReviewsModalVerTwo isModalOpen={!!displayId} pageType={pageType} />
				)}
			</div>
		</>
	);
};

export function mapStateToProps(state: Redux.State) {
	return {
		vendor: state.vendor.vendorRaw || state.typeahead.vendor,
		member: state.membership.member,
		pageType: state.page.pageType,
	};
}

const enhance = compose(
	withRouter,
	connect<StateProps, OwnProps>(mapStateToProps),
);

export default enhance(ReviewWeddingVendors);
