import { isUnifiedLightboxOpenAtom } from '@components/unified-lightbox/atoms';
import { useAppDispatch } from '@redux/hooks';
import { resetVendor } from '@redux/vendor';
import { useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';

export const useSetVendorForFilmstrip = () => {
	const isLightboxOpen = useAtomValue(isUnifiedLightboxOpenAtom);
	const previousIsLightboxOpenValue = useRef(isLightboxOpen);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (previousIsLightboxOpenValue.current && !isLightboxOpen) {
			dispatch(resetVendor());
		}
		previousIsLightboxOpenValue.current = isLightboxOpen;
	}, [isLightboxOpen]);
};
